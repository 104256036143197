import React from "react";
import { Box, Paragraph } from "theme-ui";
import Link from "components/Link";
import { alpha } from "@theme-ui/color";

const MissingStayDialog = ({ sx = {} }) => {
  return (
    <Box
      sx={{ p: 6, background: alpha("primary", 0.04), borderRadius: 1, ...sx }}
    >
      <Box>
        <Paragraph variant="subtitle1" sx={{ mb: 2 }}>
          Missing a stay?
        </Paragraph>
        <Paragraph variant="body1" sx={{ color: "text.disabled", mb: 6 }}>
          If you have an existing booking that isn't shown here, add it now so
          that we can personalise your experience.
        </Paragraph>
      </Box>
      <Link
        sx={{
          variant: "buttons.outlined",
          display: "inline-block",
          textAlign: "center",
        }}
        to="/profile/bookings/add-existing-booking"
      >
        + Add existing booking
      </Link>
    </Box>
  );
};

export default MissingStayDialog;
