import React from "react";
import { Box, Heading, Paragraph } from "theme-ui";
import Link from "components/Link";
import WavingHandIcon from "./WavingHandIcon";

const NoBookingsDialog = ({ showButton, ...props }) => {
  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: "lineDetail",
        p: 6,
        borderRadius: 1,
      }}
      {...props}
    >
      <Box>
        <Box sx={{ fontSize: "36px", color: "primary" }}>
          <WavingHandIcon />
        </Box>

        <Heading as="h6" variant="heading6" sx={{ mb: 2 }}>
          No bookings... yet!
        </Heading>
        <Paragraph variant="body1" sx={{ mb: 6, color: "text.disabled" }}>
          Time to dust off your suitcase and start planning your next adventure.
        </Paragraph>
      </Box>
      {showButton && (
        <Link sx={{ variant: "buttons.primary" }} to="/">
          Start Exploring
        </Link>
      )}
    </Box>
  );
};

export default NoBookingsDialog;
