import React from "react";
import { Box, Heading, Paragraph } from "theme-ui";
import Card from "components/card/Card";
import Link from "components/Link";
import VerticalDivider from "components/VerticalDivider";
import { useVariantByBreakpoint, stringFormatForUrl } from "utils";
import { dayAndMonth } from "utils/dateFormat";
import { propertyPageUrl } from "utils/urls";

const BookingList = ({ bookings }) => {
  const variantSelector = useVariantByBreakpoint();

  return (
    <Box>
      {bookings.map((booking) => (
        <Link
          to={propertyPageUrl(booking.location)}
          sx={{
            display: "block",
            color: "text",
            cursor: booking.location ? "pointer" : "default",
          }}
        >
          <Card
            type="white"
            orientation="landscape"
            size={variantSelector(["medium", "large"])}
            image={booking.location?.heroImage}
            contentSx={{
              display: "flex",
              alignItems: "center",
            }}
            sx={{ mb: [4, 6], height: 35 }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {booking.location && (
                <>
                  {booking.location.contactInfo && (
                    <Box>
                      {booking.location?.contactInfo.address && (
                        <Box
                          sx={{
                            display: "flex",
                            whiteSpace: "nowrap",
                            color: "text.secondary",
                          }}
                        >
                          <Paragraph
                            variant={variantSelector(["body3", "body2"])}
                            sx={{ overflow: "hidden" }}
                          >
                            {booking.location?.contactInfo.address.city}
                          </Paragraph>
                          {booking.location?.contactInfo.address.city && (
                            <Paragraph
                              variant={variantSelector(["body3", "body2"])}
                              sx={{ overflow: "hidden" }}
                            >
                              , {booking.location?.contactInfo.address.country}
                            </Paragraph>
                          )}
                        </Box>
                      )}
                    </Box>
                  )}
                  <Heading
                    as="h6"
                    variant={variantSelector(["subtitle1", null, "heading6"])}
                    sx={{ mb: 2 }}
                  >
                    {booking.location.name}
                  </Heading>
                  <Box sx={{ display: "flex" }}>
                    <Paragraph variant="body1">
                      {booking.startAt ? dayAndMonth(booking.startAt) : "-"} -
                      {booking.endAt ? dayAndMonth(booking.endAt) : "-"}
                    </Paragraph>
                    <VerticalDivider sx={{ px: 2 }} />
                    <Paragraph variant="body1">
                      {booking.numberOfAdults + booking.numberOfChildren} Guests
                    </Paragraph>
                  </Box>
                </>
              )}
            </Box>
          </Card>
        </Link>
      ))}
    </Box>
  );
};

export default BookingList;
