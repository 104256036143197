import React, { useMemo } from "react";
import { Flex, Heading } from "theme-ui";
import ProfileLayout from "profile/ProfileLayout";
import NoBookingsDialog from "profile/bookings/NoBookingsDialog";
import MissingStayDialog from "profile/bookings/MissingStayDialog";
import useFetchBookings from "utils/useFetchBookings";
import BookingList from "profile/bookings/BookingList";
import { LoadingIndicator } from "components";
import SEO from "components/SEO";

export default function Bookings({}) {
  const BREADCRUMB_LINKS = [
    { text: "My profile", to: "/profile" },
    { text: "Bookings", to: "/profile/bookings" },
  ];
  const PAGE_NAME = "Bookings";

  const { bookingListError, loading, currentAndFutureBookings } =
    useFetchBookings();

  const filteredBookingList = useMemo(
    () => currentAndFutureBookings.filter((booking) => booking.location),
    [currentAndFutureBookings]
  );

  return (
    <ProfileLayout page={PAGE_NAME} breadcrumbs={BREADCRUMB_LINKS}>
      <Flex sx={{ flexDirection: "column", gap: 6, mx: [4, 0] }}>
        <Heading as="h6" variant="heading6">
          My Bookings
        </Heading>

        {loading && <LoadingIndicator />}

        {!loading && filteredBookingList.length === 0 && !bookingListError && (
          <NoBookingsDialog showButton />
        )}

        {!loading && filteredBookingList.length > 0 && (
          <BookingList bookings={filteredBookingList} />
        )}

        <MissingStayDialog />
      </Flex>
    </ProfileLayout>
  );
}

export const Head = () => <SEO title="Bookings" />;
