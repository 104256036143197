import React from "react";

const WavingHandIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7165 8.25008L5.8165 14.1501C0.283171 19.6834 0.283171 28.6501 5.8165 34.1834C11.3498 39.7167 20.3165 39.7167 25.8498 34.1834L35.8665 24.1667C37.4832 22.5501 37.4832 19.9001 35.8665 18.2667C35.6665 18.0667 35.4498 17.8834 35.2165 17.7334L35.8665 17.0834C37.4832 15.4667 37.4832 12.8167 35.8665 11.1834C35.5998 10.9167 35.2832 10.6834 34.9665 10.5001C35.6332 8.96675 35.3498 7.13341 34.0998 5.88341C32.6498 4.43341 30.3998 4.28341 28.7665 5.41675C28.5998 5.16675 28.4165 4.93341 28.1998 4.71675C26.5832 3.10008 23.9332 3.10008 22.2998 4.71675L18.1165 8.90008C17.9665 8.66675 17.7832 8.45008 17.5832 8.25008C15.9665 6.63341 13.3332 6.63341 11.7165 8.25008ZM14.0665 10.6167C14.3998 10.2834 14.9165 10.2834 15.2498 10.6167C15.5832 10.9501 15.5832 11.4667 15.2498 11.8001L9.94984 17.1001C11.8998 19.0501 11.8998 22.2167 9.94984 24.1667L12.2998 26.5167C14.7165 24.1001 15.3332 20.5667 14.1665 17.5834L24.6665 7.08341C24.9998 6.75008 25.5165 6.75008 25.8498 7.08341C26.1832 7.41675 26.1832 7.93341 25.8498 8.26675L18.1832 15.9334L20.5332 18.2834L30.5498 8.26675C30.8832 7.93341 31.3998 7.93341 31.7332 8.26675C32.0665 8.60008 32.0665 9.11675 31.7332 9.45008L21.7165 19.4667L24.0665 21.8167L32.3165 13.5667C32.6498 13.2334 33.1665 13.2334 33.4998 13.5667C33.8332 13.9001 33.8332 14.4167 33.4998 14.7501L24.0665 24.1834L26.4165 26.5334L32.3165 20.6334C32.6498 20.3001 33.1665 20.3001 33.4998 20.6334C33.8332 20.9667 33.8332 21.4834 33.4998 21.8167L23.4998 31.8334C19.2665 36.0667 12.4165 36.0667 8.18317 31.8334C3.94984 27.6001 3.94984 20.7501 8.18317 16.5167L14.0665 10.6167ZM38.3332 28.3334C38.3332 33.8501 33.8498 38.3334 28.3332 38.3334V35.8334C32.4665 35.8334 35.8332 32.4667 35.8332 28.3334H38.3332ZM1.6665 11.6667C1.6665 6.15008 6.14984 1.66675 11.6665 1.66675V4.16675C7.53317 4.16675 4.1665 7.53341 4.1665 11.6667H1.6665Z"
        fill="currentColor"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default WavingHandIcon;
