/* @jsx jsx */
import React, { useState, useContext, useEffect } from "react";
import { Box, Divider, Flex, Heading, Paragraph, Text, jsx } from "theme-ui";
import Link from "components/Link";
import { AuthContext } from "Authentication/AuthProvider";

import { ArrowBack } from "@material-ui/icons";
import PublicIcon from "@material-ui/icons/Public";
import PersonOutlined from "@material-ui/icons/PersonOutlined";
import DateRangeOutlined from "@material-ui/icons/DateRangeOutlined";
import { navigate } from "gatsby";
import { useVariantByBreakpoint } from "utils";
import BrandLogo from "components/BrandLogo";

const MenuItem = ({ Icon, isLastItem, link, title, onClick }) => {
  return (
    <>
      <Link
        to={link}
        sx={{
          variant: "body1",
          display: "flex",
          alignItems: "center",
          width: "full",
          color: "text",
          cursor: "pointer",
          textDecoration: "none",
          "&:active, &:link": {
            color: "text",
          },
          px: 4,

          background:
            link === window?.location?.pathname ? "primary.selected" : "",
        }}
        onClick={onClick}
      >
        <Icon sx={{ color: "text.secondary", width: 6, height: 6 }} />
        <Paragraph variant="body1" sx={{ flex: "1", p: 4 }}>
          {title}
        </Paragraph>
      </Link>
      {!isLastItem && <Divider sx={{ width: "full", m: 0 }} />}
    </>
  );
};

const MenuItems = ({ title, children }) => {
  return (
    <Box sx={{ my: 8 }}>
      <Box sx={{ my: 2 }}>
        <Text variant="overline" sx={{ color: "text.secondary", ml: 4 }}>
          {title}
        </Text>
      </Box>
      <Box sx={{ bg: "componentBG1" }}>{children}</Box>
    </Box>
  );
};

const Menu = ({ hideBackArrow }) => {
  const { profile, signOut } = useContext(AuthContext);
  const variantSelector = useVariantByBreakpoint();

  const [selectedMenuItem, setSelectedMenuItem] = useState(
    "personalInformation"
  );

  const [showOnMobile, setShowOnMobile] = useState(
    window?.location?.pathname === "/profile"
  );

  return (
    <>
      <Box
        sx={{
          display: [showOnMobile ? "block" : "none", null, "block"],
          height: "100vh",
          bg: [null, null, "componentBG1"],
          pl: [null, null, 20],
        }}
      >
        <Flex
          sx={{
            py: 10,
            flexDirection: ["column", "row"],
            alignItems: ["center", null],
            ml: [0, 10, 0],
          }}
        >
          <BrandLogo width={20} />
          <Flex sx={{ flexDirection: "column", justifyContent: "center" }}>
            <Heading variant="heading6">
              {profile?.firstName} {profile?.lastName}
            </Heading>
            {false && (
              <Paragraph variant="body2" sx={{ color: "text.secondary" }}>
                Member since placeholder
              </Paragraph>
            )}
          </Flex>
        </Flex>

        <MenuItems title="Account">
          <MenuItem
            title="Personal information"
            Icon={PersonOutlined}
            link={variantSelector([
              "/profile/personal-information",
              null,
              "/profile",
            ])}
            onClick={() => {
              setSelectedMenuItem("personalInformation");
            }}
            selectedMenuItem={selectedMenuItem}
          />
          <MenuItem
            title="Bookings"
            Icon={DateRangeOutlined}
            link="/profile/bookings"
            isLastItem
            onClick={() => {
              setSelectedMenuItem("bookings");
              navigate("/profile/bookings");
              setShowOnMobile(false);
            }}
            selectedMenuItem={selectedMenuItem}
          />
        </MenuItems>

        <Box>
          <MenuItem
            title="Log out"
            Icon={PublicIcon}
            isLastItem
            onClick={signOut}
            selectedMenuItem={false}
          />
        </Box>
      </Box>
      {!hideBackArrow && (
        <Text
          variant="button1"
          sx={{
            display: [showOnMobile ? "none" : "flex", null, "none"],
            alignItems: "center",
            cursor: "pointer",
            color: "primary",
          }}
          onClick={() => {
            navigate(-1);
            setShowOnMobile(true);
          }}
        >
          <ArrowBack sx={{ ml: [4, 20] }} /> Back
        </Text>
      )}
    </>
  );
};

export default Menu;
