import React from "react";
import { Box, Grid } from "theme-ui";
import NavBar from "components/navbar";
import Menu from "./Menu";

const ProfileLayout = ({
  children,
  page,
  breadcrumbs,
  sx,
  profile,
  ...props
}) => {
  return (
    <Box as="main" {...props}>
      <NavBar
        currentPage={page}
        breadcrumbs={breadcrumbs}
        className="fullSize"
      />
      <Grid
        columns={[1, null, "minmax(max-content, 25rem) auto"]}
        sx={{ mt: [6, null, 0] }}
      >
        <Menu hideBackArrow={props.hideBackArrow} />
        <Box sx={{ mx: [0, 20], my: [0, null, 10] }}>{children}</Box>
      </Grid>
    </Box>
  );
};

export default ProfileLayout;
